import { Container, Layout } from 'components/common'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'

const WikiPage = ({
  data: {
    note: { body },
    site: {
      siteMetadata: { title },
    },
  },
  ...props
}) => (
  <Layout {...props} title={title}>
    <Container>
      <MDXRenderer>{body}</MDXRenderer>
    </Container>
  </Layout>
)

export default WikiPage
